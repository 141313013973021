import "core-js/modules/es6.function.name";
import React from 'react';

function SocialIcons(_ref) {
  var data = _ref.data;
  return React.createElement(React.Fragment, null, React.createElement("a", {
    href: "https://www.instagram.com/codengagecom/",
    rel: "noopener",
    target: "_blank",
    className: "no-outline"
  }, React.createElement("img", {
    src: data.instagram.publicURL,
    alt: data.instagram.name
  })), React.createElement("a", {
    href: "https://www.facebook.com/codengagecom/",
    rel: "noopener",
    target: "_blank",
    className: "no-outline"
  }, React.createElement("img", {
    src: data.facebook.publicURL,
    alt: data.facebook.name
  })), React.createElement("a", {
    href: "https://www.linkedin.com/company/codengage",
    rel: "noopener",
    target: "_blank",
    className: "no-outline"
  }, React.createElement("img", {
    src: data.linkedin.publicURL,
    alt: data.linkedin.name
  })));
}

export default SocialIcons;