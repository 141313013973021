import staticQueryData from "../../../public/static/d/2308542329.json";
import React from "react";
import { StaticQuery } from "gatsby";
import Anchor from '../anchor';

function Codengage() {
  return React.createElement(StaticQuery, {
    query: "2308542329",
    render: function render(data) {
      return React.createElement("section", {
        className: "bg-white h-auto"
      }, React.createElement("div", {
        className: "container mxa-ha pt-30px md:pt-40px"
      }, React.createElement(Anchor, {
        id: "empresa"
      }), React.createElement("div", {
        className: "mx-20px md:mx-auto text-center font-semibold text-34 md:text-48-97 lg:text-58"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-black-1"
      }, "Uma empresa formada "), React.createElement("span", {
        className: "text-cian-1"
      }, "por pessoas."))), React.createElement("div", {
        className: "md:w-662px lg:w-889px h-auto mx-20px md:mx-auto text-center mt-30px md:mt-25px mb-38px"
      }, data.content.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "txtc-14-18 text-black-3"
        }, node.excerpt);
      }))), React.createElement("div", {
        className: "h-auto xl:h-677px md:w-full bg-cover px-20px",
        style: {
          backgroundImage: "url(" + data.background_full.publicURL + ")"
        }
      }, React.createElement("div", {
        className: "lg:w-581px h-full mx-auto max-w-366 md:max-w-480 lg:max-w-677 bg-cian-2"
      }, React.createElement("div", {
        className: "h-full ml-37px md:ml-67px mr-24px md:mr-43px pt-33px lg:pt-61px"
      }, React.createElement("div", {
        className: "h-161px lg:h-293px mb-33px lg:mb-60px mx-auto"
      }, React.createElement("img", {
        className: "h-full lg:float-left",
        src: data.lula.publicURL,
        alt: "lula I love tech"
      })), React.createElement("div", {
        className: "h-139px md:h-213px mb-28px lg:mb-50px text-left text-16 md:text-24 lg:text-26 mx-auto"
      }, React.createElement("span", {
        className: "text-white"
      }, "Acreditamos muito nas pessoas"), React.createElement("span", null, ", somente elas possuem o poder da transforma\xE7\xE3o. Uma pessoa motivada pode acessar o inacess\xEDvel,"), React.createElement("span", {
        className: "text-white"
      }, " rompendo barreiras"), React.createElement("span", null, ", saindo da imagina\xE7\xE3o para a constru\xE7\xE3o em pouqu\xEDssimo tempo."))))));
    },
    data: staticQueryData
  });
}

export default Codengage;