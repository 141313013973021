import staticQueryData from "../../../public/static/d/3834803203.json";
import React from "react";
import { StaticQuery } from "gatsby";
import Anchor from '../anchor';

function JoinIn() {
  return React.createElement(StaticQuery, {
    query: "3834803203",
    render: function render(data) {
      return React.createElement("section", {
        className: "h-auto pt-30px md:pt-40px pb-30px lg:pb-60px"
      }, React.createElement(Anchor, {
        id: "talentos"
      }), React.createElement("div", {
        className: "container mx-auto text-center font-semibold text-34 md:text-46 xl:text-58"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-black-3"
      }, "Junte-se "), React.createElement("span", {
        className: "text-cian-1"
      }, "a n\xF3s."))), React.createElement("div", {
        className: "max-w-360 sm:max-w-424 lg:max-w-848 mxa-ha"
      }, React.createElement("div", {
        className: "mx-20px lg:inline-flex lg:w-full lg:justify-between"
      }, React.createElement("div", {
        className: "mx-auto lg:mx-0 lg:float-left w-138px md:w-162px mt-18px"
      }, React.createElement("img", {
        src: data.coffee.publicURL,
        alt: "coffee"
      })), React.createElement("div", {
        className: "lg:float-right lg:w-541px mt-30px lg:mt-48px flex lg:content-between flex-wrap"
      }, data.content.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "txtc-14-18 txtc-lgtxtl"
        }, node.excerpt);
      }), React.createElement("a", {
        href: "https://codengage.compleo.com.br/",
        target: "_blank",
        rel: "noopener",
        className: "no-outline btn text-center py-13px h-52px md:w-245px mx-auto lg:ml-3px mt-20px lg:mt-0 bg-black-1 hover:bg-black-5 font-semibold"
      }, "VAGAS ABERTAS")))));
    },
    data: staticQueryData
  });
}

export default JoinIn;