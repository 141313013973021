import React from "react";

function Alert(_ref) {
  var type = _ref.type,
      title = _ref.title,
      content = _ref.content,
      show = _ref.show,
      onClose = _ref.onClose;
  var classes = "ab-inv";

  if (show) {
    switch (type) {
      case 'success':
        classes = "alert text-display bg-green-2 border-green-1 text-green-1";
        break;

      case 'error':
        classes = "alert text-display bg-red-2 border-red-1 text-red-1";
        break;

      default:
        break;
    }

    return React.createElement("section", {
      id: "alert",
      className: classes,
      role: "alert"
    }, React.createElement("strong", {
      className: "font-bold"
    }, title, "\xA0"), React.createElement("span", {
      className: "block sm:inline"
    }, content), React.createElement("span", {
      onClick: function onClick() {
        return onClose();
      },
      className: "absolute top-0 bottom-0 right-0 px-4 py-3"
    }, React.createElement("svg", {
      className: "fill-current h-6 w-6",
      role: "button",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20"
    }, React.createElement("title", null, "Close"), React.createElement("path", {
      d: "M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
    }))));
  }

  return null;
}

export default Alert;