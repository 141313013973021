import "core-js/modules/es6.function.name";
import staticQueryData from "../../public/static/d/2778551623.json";
import { StaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import SocialIcons from './socialIcons';

function Header(_ref) {
  var scroll = _ref.scroll;

  var _useState = useState(false),
      isExpanded = _useState[0],
      toggleExpansion = _useState[1];

  return React.createElement(StaticQuery, {
    query: "2778551623",
    render: function render(data) {
      return React.createElement("nav", {
        className: isExpanded ? "bg-white" : ""
      }, React.createElement("div", {
        className: scroll ? "mxa-ha" : "mxa-ha md:px-20px xl:px-0"
      }, React.createElement("div", {
        className: scroll ? "ab-inv hidden" : "container mx-auto ab-inv md-vis-rel h-58px xl:w-1110px md:pr-20px lg:pr-0"
      }, React.createElement("div", {
        className: "inline-flex justify-between w-73px h-18px float-right md:mt-20px lg:mt-30px"
      }, React.createElement(SocialIcons, {
        data: data
      }))), React.createElement("div", {
        className: scroll ? "lg:px-20px xl:px-0 transition-fast transition-timing-ease-out transition-property-bg transition-delay-long bg-white fixed h-64px lg:h-80px w-full top-0 z-1 shadow" : "mt-20px md:mt-0 h-40px md:h-60px lg:h-80px w-full"
      }, React.createElement("div", {
        className: scroll ? "container mx-auto menu-full j-grid items-center xl:w-1110px h-full" : "container mx-auto pl-20px lg:pl-0 j-grid items-center xl:w-1110px h-full"
      }, React.createElement(Link, {
        to: "/",
        className: "no-outline float-left flex items-center w-126px lg:w-163px lg:h-66px"
      }, React.createElement("img", {
        src: data.logo.publicURL,
        alt: data.logo.name
      })), React.createElement("div", {
        className: "md:float-right absolute hidden md-vis-rel md:inline-flex items-center justify-between float-right md:w-456px lg:w-630px h-full pr-20px lg:pr-0 font-display text-black-1"
      }, React.createElement(Link, {
        className: "no-outline",
        to: "/#empresa"
      }, "Empresa"), React.createElement(Link, {
        className: "no-outline",
        to: "/#servicos"
      }, "Servi\xE7os"), React.createElement(Link, {
        className: "no-outline",
        to: "/#clientes"
      }, "Clientes"), React.createElement(Link, {
        className: "no-outline",
        to: "/#testemunho"
      }, "Testemunho"), React.createElement(Link, {
        className: "no-outline",
        to: "/#talentos"
      }, "Talentos"), React.createElement(Link, {
        className: "no-outline",
        to: "/#contato"
      }, "Contato")), React.createElement("button", {
        className: isExpanded ? "no-outline ab-inv items-center mr-26px hidden" : "no-outline md-ab-inv items-center relative visible mr-26px",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_menu.publicURL,
        alt: data.icon_menu.name
      })), React.createElement("button", {
        className: isExpanded ? "no-outline md-ab-inv items-center relative visible mr-26px text-24" : "no-outline hidden ab-inv md-ab-inv mr-26px text-24",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, "\xD7"))), React.createElement("div", {
        className: isExpanded ? scroll ? "fixed top-52 menu-mobile" : "menu-mobile absolute" : "ab-inv hidden"
      }, React.createElement("div", {
        className: "c-grid w-auto h-auto mx-20px xs:mx-auto xs:px-20px sm:mx-20px font-display text-14 text-black-1 max-w-360 sm:max-w-640"
      }, React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#empresa",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Empresa"), React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#servicos",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Servi\xE7os"), React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#clientes",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Clientes"), React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#testemunho",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Testemunho"), React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#talentos",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Talentos"), React.createElement(Link, {
        className: "header-link no-outline",
        to: "/#contato",
        onClick: function onClick() {
          return toggleExpansion(!isExpanded);
        }
      }, React.createElement("img", {
        src: data.icon_play.publicURL,
        alt: "icon list"
      }), "\xA0Contato"), React.createElement("div", {
        className: "inline-flex justify-between w-100px h-18px mt-22px mx-auto mb-18px"
      }, React.createElement(SocialIcons, {
        data: data
      }))))));
    },
    data: staticQueryData
  });
}

export default Header;