import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/1672775217.json";
import React from 'react';
import Slider from "react-slick";
import { StaticQuery } from 'gatsby';
import Anchor from '../anchor';

function Partners() {
  var sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: React.createElement(React.Fragment, null),
    prevArrow: React.createElement(React.Fragment, null)
  };
  return React.createElement(StaticQuery, {
    query: "1672775217",
    render: function render(data) {
      return React.createElement("section", {
        className: "h-auto bg-cover",
        style: {
          backgroundImage: "url(" + data.background_full.publicURL + ")"
        }
      }, React.createElement("div", {
        className: "bg-black-2 h-full pt-30px lg:pt-40px"
      }, React.createElement(Anchor, {
        id: "clientes"
      }), React.createElement("div", {
        className: "container mx-auto h-full xl:max-w-1110"
      }, React.createElement("div", {
        className: "sec-intro font-semibold mx-20px"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-white"
      }, "Empresas que "), React.createElement("span", {
        className: "text-cian-1"
      }, "est\xE3o conosco."))), React.createElement("div", {
        className: "lg:max-w-980 xl:w-817px mx-auto text-center mt-25px mb-20px md:mb-40px lg:mb-53px"
      }, data.partners.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "txtc-14-18 text-white mx-20px"
        }, node.excerpt);
      })), React.createElement("div", {
        className: "c-grid p-disp lg:max-w-980 xl:max-w-1110 md:h-245px xl:h-342px ab-inv md-vis-rel pb-30px lg:pb-50px px-20px lg:px-0 lg:mx-auto"
      }, data.partnersmd.edges.map(function (_ref2) {
        var node = _ref2.node;
        return React.createElement("div", {
          key: node.frontmatter.order,
          className: "j-grid md:w-full"
        }, data.icons.nodes.map(function (_ref3) {
          var name = _ref3.name,
              publicURL = _ref3.publicURL;
          return name === node.frontmatter.first && React.createElement("img", {
            className: "md:w-135px lg:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }), data.icons.nodes.map(function (_ref4) {
          var name = _ref4.name,
              publicURL = _ref4.publicURL;
          return name === node.frontmatter.second && React.createElement("img", {
            className: "md:w-135px lg:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }), data.icons.nodes.map(function (_ref5) {
          var name = _ref5.name,
              publicURL = _ref5.publicURL;
          return name === node.frontmatter.third && React.createElement("img", {
            className: "md:w-135px lg:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }), data.icons.nodes.map(function (_ref6) {
          var name = _ref6.name,
              publicURL = _ref6.publicURL;
          return name === node.frontmatter.fourth && React.createElement("img", {
            className: "md:w-135px lg:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }));
      })), React.createElement(Slider, Object.assign({}, sliderSettings, {
        className: "h-73px md-ab-inv flex mt-50px mb-73px"
      }), data.partnerssm.edges.map(function (_ref7) {
        var node = _ref7.node;
        return React.createElement("div", {
          key: node.frontmatter.order,
          className: "carousel no-outline w-full px-auto flex-row inline-flex justify-between sm:justify-around"
        }, data.icons.nodes.map(function (_ref8) {
          var name = _ref8.name,
              publicURL = _ref8.publicURL;
          return name === node.frontmatter.first && React.createElement("img", {
            className: "w-130px xs:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }), data.icons.nodes.map(function (_ref9) {
          var name = _ref9.name,
              publicURL = _ref9.publicURL;
          return name === node.frontmatter.second && React.createElement("img", {
            className: "w-130px xs:w-150px",
            key: name,
            src: publicURL,
            alt: "icon " + name
          });
        }));
      })))));
    },
    data: staticQueryData
  });
}

export default Partners;