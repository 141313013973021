import staticQueryData from "../../public/static/d/1223810458.json";
import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery } from 'gatsby';

function SEO(_ref) {
  var description = _ref.description,
      lang = _ref.lang,
      meta = _ref.meta,
      keywords = _ref.keywords,
      title = _ref.title;
  return React.createElement(StaticQuery, {
    query: detailsQuery,
    render: function render(data) {
      var metaDescription = description || data.site.siteMetadata.description;
      return React.createElement(Helmet, {
        htmlAttributes: {
          lang: lang
        },
        title: "" + data.site.siteMetadata.title + data.site.siteMetadata.titleTemplate // titleTemplate={data.site.siteMetadata.titleTemplate}
        ,
        meta: [{
          name: "author",
          content: data.site.siteMetadata.author
        }, {
          name: "image",
          content: data.image.publicURL
        }, {
          name: "keywords",
          content: data.site.siteMetadata.keywords
        }, {
          name: "description",
          content: metaDescription
        }, {
          name: "og:image",
          content: data.image.publicURL
        }, {
          property: "og:title",
          content: title
        }, {
          property: "og:description",
          content: metaDescription
        }, {
          property: "og:type",
          content: "website"
        }, {
          name: "twitter:card",
          content: "summary"
        }, {
          name: "twitter:creator",
          content: data.site.siteMetadata.author
        }, {
          name: "twitter:title",
          content: title
        }, {
          name: "twitter:description",
          content: metaDescription
        }].concat(keywords.length > 0 ? {
          name: "keywords",
          content: keywords.join(", ")
        } : []).concat(meta)
      });
    },
    data: staticQueryData
  });
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: ""
};
export default SEO;
var detailsQuery = "1223810458";