import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/3802918095.json";
import React from "react";
import { StaticQuery } from "gatsby";
import Anchor from '../anchor';

function SecTeam() {
  return React.createElement(StaticQuery, {
    query: "3802918095",
    render: function render(data) {
      return React.createElement("section", {
        className: "container mxa-ha lg:w-1110px pt-30px lg:pt-40px"
      }, React.createElement(Anchor, {
        id: "servicos"
      }), React.createElement("div", {
        className: "sec-intro font-semibold lg:mx-auto mx-20px"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-black-1"
      }, "Um time "), React.createElement("span", {
        className: "text-cian-1"
      }, "completo de TI."))), React.createElement("div", {
        className: "w-auto md:w-662px lg:w-889px h-auto lg:h-91px mx-20px md:mx-auto text-center mt-23px md:mt-25px mb-40px md:mb-38px"
      }, data.content.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "text-14 md:text-17 text-black-3"
        }, node.excerpt);
      })), React.createElement("div", {
        className: "w-full mxa-ha lg:h-405px xl:h-469px mb-27px md:mb-40px lg:mb-50px lg:max-w-980 xl:max-w-1110"
      }, React.createElement("div", {
        className: "h-auto lg:float-left w-320px md:w-546px lg:w-456px xl:w-546px mxa-ha lg:mx-0"
      }, React.createElement("img", {
        className: "float-left img-mobile",
        src: data.mobile1.publicURL,
        alt: data.mobile1.name
      }), React.createElement("img", {
        className: "float-right img-mobile",
        src: data.mobile2.publicURL,
        alt: data.mobile2.name
      }), React.createElement("img", {
        className: "mb-39px lg:mb-0 hidden md:block",
        src: data.mobile.publicURL,
        alt: data.mobile.name
      })), React.createElement("div", {
        className: "lg:float-right w-320px md:w-456px xl:h-full mx-auto lg:mx-0 txtc-lgtxtl"
      }, React.createElement("div", {
        className: "h-auto xl:h-247px"
      }, React.createElement("p", {
        className: "font-semibold text-28 lg:text-34 xl:text-46"
      }, "Buscamos a excel\xEAncia do produto por meio da cultura criativa, design e engenharia de software.")), React.createElement("div", {
        className: "h-auto lg:h-165px mt-30px lg:mt-43px"
      }, React.createElement("p", {
        className: "text-18 lg:text-26 text-black-3 font-display"
      }, "Oferecemos a assessoria completa para empresas quem desejam desenvolver novos produtos, ou refor\xE7ar sua equipe t\xE9cnica atual.")))));
    },
    data: staticQueryData
  });
}

export default SecTeam;