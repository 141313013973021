import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/2586116700.json";
import React from "react";
import { StaticQuery } from "gatsby";

function Initial(_ref) {
  var scroll = _ref.scroll;
  return React.createElement(StaticQuery, {
    query: "2586116700",
    render: function render(data) {
      return React.createElement("section", {
        className: scroll ? "container mxa-ha initial mt-114px md:mt-154px lg:mt-225px xl:mt-298px mb-55px lg:mb-85px xl:mb-165px" : "container mxa-ha initial mt-55px lg:mt-85px xl:mt-165px mb-55px lg:mb-85px xl:mb-165px"
      }, React.createElement("div", {
        className: "mx-auto px-20px max-w-360 sm:max-w-380 md:max-w-546 lg:max-w-full"
      }, React.createElement("div", {
        className: "lg:float-left h-172px sm:h-209px md:h-307px lg:h-full mx-auto lg:mx-0 lg:max-w-424 xl:max-w-546"
      }, React.createElement("img", {
        className: "float-left",
        src: data.balanco.publicURL,
        alt: data.balanco.name
      })), React.createElement("div", {
        className: "lg:float-right mt-50px lg:mt-0 txtc-lgtxtl mx-auto max-w-320 sm:max-w-380 lg:max-w-452 xl:max-w-546"
      }, React.createElement("div", {
        className: "h-51px lg:h-60px xl:h-79px w-280px xs:w-298px initial-xs mx-auto lg:mx-0 font-semibold text-42 xs:text-46 sm:text-48-97"
      }, React.createElement("h1", null, React.createElement("span", {
        className: "text-black-1"
      }, "Menos \xE9 "), React.createElement("span", {
        className: "text-cian-1"
      }, "mais."))), React.createElement("div", {
        className: "lg-w-428 h-85px lg:h-107px xl:h-126px mx-auto lg:ml-3px mt-15px lg:mt-20px xl:mt-38px lg:mr-25px"
      }, data.content.edges.map(function (_ref2) {
        var node = _ref2.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "text-18 lg:text-24 xl:text-26 text-black-3 txtc-lgtxtl"
        }, node.excerpt);
      })), React.createElement("a", {
        href: "/#empresa",
        className: "no-outline btn text-center block py-13px h-52px md:w-245px mt-30px xl:mt-40px lg:ml-3px mx-auto bg-cian-1 hover:bg-cian-3 font-semibold"
      }, "INICIAR NAVEGA\xC7\xC3O"))));
    },
    data: staticQueryData
  });
}

export default Initial;