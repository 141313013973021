import "core-js/modules/es6.regexp.constructor";
import staticQueryData from "../../public/static/d/1163304594.json";
import React, { useState, useReducer } from 'react';
import { StaticQuery } from "gatsby";
import { Link } from 'gatsby';
import SocialIcons from './socialIcons';
import Alert from './alert';

function Footer() {
  var _this = this;

  var _useState = useState(''),
      email = _useState[0],
      changeEmail = _useState[1];

  var _useState2 = useState(false),
      showAlert = _useState2[0],
      changeShowAlert = _useState2[1];

  var initialState = {
    alert: '',
    title: '',
    content: ''
  };

  var _useReducer = useReducer(reducer, initialState),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  function handleCloseAlert() {
    changeShowAlert(false);
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'incomplete':
        changeShowAlert(true);
        return {
          alert: 'error',
          title: 'Campos vazios!',
          content: 'Por favor, informe um email.'
        };

      case 'error':
        changeShowAlert(true);
        return {
          alert: action.type,
          title: 'Falha ao registrar e-mail!',
          content: 'Por favor, tente novamente mais tarde.'
        };

      case 'invalid_email':
        changeShowAlert(true);
        return {
          alert: "error",
          title: "Email inválido!",
          content: "Por favor, insira um email válido."
        };

      case 'success':
        changeShowAlert(true);
        return {
          alert: action.type,
          title: 'Sucesso!',
          content: 'Obrigado por assinar nossa Newsletter.'
        };

      default:
        return {
          initialState: initialState
        };
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (email) {
      var testEmail = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);

      if (!testEmail.test(email)) {
        dispatch({
          type: 'invalid_email'
        });
      } else {
        var data = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
          },
          mode: 'cors',
          body: JSON.stringify({
            email_address: email,
            status: 'subscribed',
            tag: 'Newsletter'
          })
        };
        fetch(process.env.GATSBY_API_CONTACT, data).then(function (res) {
          return res.json();
        }).then(function (res) {
          res.success ? dispatch({
            type: 'success'
          }) : dispatch({
            type: 'error'
          });
        }).catch(function (e) {
          dispatch({
            type: 'error'
          });
        });
      }
    } else {
      dispatch({
        type: 'incomplete'
      });
    }
  }

  return React.createElement(StaticQuery, {
    query: "1163304594",
    render: function render(data) {
      return React.createElement("div", {
        className: "h-auto"
      }, React.createElement("div", {
        className: "h-auto pt-30px lg:pt-56px"
      }, React.createElement("div", {
        className: "container mx-auto relative max-w-320px sm:max-w-424 lg:max-w-848 xl:max-w-1025"
      }, React.createElement(Alert, {
        type: state.alert,
        title: state.title,
        content: state.content,
        show: showAlert,
        onClose: handleCloseAlert.bind(_this)
      }), React.createElement("div", {
        className: "w-auto mx-20px pb-203px lg:pb-54px lg:inline-flex lg:justify-between"
      }, React.createElement("div", {
        className: "lg:w-358px lg:h-135px lg:pt-7px lg:pb-6px flex lg:content-between flex-wrap text-14 txtc-lgtxtl"
      }, React.createElement("h3", {
        className: "font-bold w-full lg:w-auto mb-20px lg:mb-0"
      }, "Assine nossa Newsletter mensal"), React.createElement("p", {
        className: "font-display lg:w-350px mb-20px lg:mb-0"
      }, "Acompanhe-nos recebendo mensalmente novidades sobre o mercado de tecnologia no seu email."), React.createElement("form", {
        className: "mx-auto lg:mx-0 inline-flex w-320px"
      }, React.createElement("input", {
        "aria-label": "E-mail Newsletter",
        className: "no-outline on-focus bg-white float-left xs:w-215px h-41px mr-10px px-16px font-display text-cian-1 border border-black-8 rounded text-14",
        type: "email",
        placeholder: "Email",
        required: true,
        value: email,
        onChange: function onChange(e) {
          return changeEmail(e.target.value);
        }
      }), React.createElement("button", {
        className: "no-outline on-focus float-right w-85px lg:w-123px h-41px ml-10px bg-cian-1 hover:bg-cian-3 text-12 text-white font-semibold rounded",
        type: "submit",
        onClick: function onClick(e) {
          return handleSubmit(e);
        }
      }, "ASSINAR"))), React.createElement("div", {
        className: "absolute lg:relative w-full left-0 -bottom-28 xs:-bottom-32 lg:bottom-0 lg:w-180px lg:h-228px lg:pt-5px lg:-mb-85px xl:mr-0 xl:ml-85px"
      }, React.createElement("img", {
        className: "mx-auto",
        src: data.mimi.publicURL,
        alt: "mimi codengage"
      })), React.createElement("div", {
        className: "w-full lg:w-auto justify-between inline-flex h-148px mt-29px lg:mt-0 lg:mb-0"
      }, React.createElement("div", {
        className: "lg:w-161px lg:h-119px lg:mb-26px pt-3px xl:ml-88px text-14 font-display"
      }, React.createElement("p", null, "+55 46 2605-0031"), React.createElement("p", null, "+55 46 99985-8593"), React.createElement("p", null, "contato@codengage.com"), React.createElement("p", null, "comercial@codengage.com"), React.createElement("p", null, "rh@codengage.com")), React.createElement("div", {
        className: "c-grid w-82px lg:h-full lg:ml-40px xl:ml-68px font-display text-14 text-cian-3"
      }, React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#empresa"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Empresa"), React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#servicos"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Servi\xE7os"), React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#clientes"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Clientes"), React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#testemunho"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Testemunho"), React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#talentos"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Talentos"), React.createElement(Link, {
        className: "inline-flex no-outline",
        to: "/#contato"
      }, React.createElement("img", {
        src: data.icon_play_cian.publicURL,
        alt: "icon list"
      }), "\xA0Contato"))))), React.createElement("div", {
        className: "h-50px bg-black-1"
      }, React.createElement("div", {
        className: "mx-20px xl:mx-auto lg:max-w-1110 h-full"
      }, React.createElement("div", {
        className: "inline-flex justify-end h-11px items-center mt-21px mb-18px"
      }, React.createElement("img", {
        className: "lg:w-7px h-7px",
        src: data.copyright_regular.publicURL,
        alt: "copyright icon"
      }), React.createElement("p", {
        className: "text-white text-10 font-display"
      }, "\xA0Codengage 2019")), React.createElement("div", {
        className: "relative inline-flex justify-between w-73px h-18px float-right mt-17px mb-15px"
      }, React.createElement(SocialIcons, {
        data: data
      }))))));
    },
    data: staticQueryData
  });
}

export default Footer;