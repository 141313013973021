import staticQueryData from "../../public/static/d/1044757290.json";
import React, { useState, useEffect } from 'react';
import { StaticQuery } from 'gatsby';
import SEO from './seo';
import Header from './header';
import Footer from './footer';
import Team from './sections/Team';
import Squad from './sections/Squad';
import JoinIn from './sections/JoinIn';
import Initial from './sections/Initial';
import Contact from './sections/Contact';
import Meeting from './sections/Meeting';
import Partners from './sections/Partners';
import Codengage from './sections/Codengage';
import Testimony from './sections/Testimony';

function Layout() {
  var _useState = useState(false),
      isScroll = _useState[0],
      toggleScroll = _useState[1];

  useEffect(function () {
    window.addEventListener('scroll', function () {
      if (window.innerWidth >= 1024) {
        window.pageYOffset >= 55 ? toggleScroll(true) : toggleScroll(false);
      } else if (window.innerWidth >= 768) {
        window.pageYOffset >= 45 ? toggleScroll(true) : toggleScroll(false);
      } else {
        window.pageYOffset >= 8 ? toggleScroll(true) : toggleScroll(false);
      }
    });
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(SEO, {
    title: "Codengage",
    lang: "pt-BR"
  }), React.createElement(StaticQuery, {
    query: "1044757290",
    render: function render(data) {
      return React.createElement("div", {
        className: "bg-gray-1 flex flex-col font-display"
      }, React.createElement(Header, {
        scroll: isScroll
      }), React.createElement(Initial, {
        scroll: isScroll
      }), React.createElement(Codengage, null), React.createElement(Team, null), React.createElement(Squad, null), React.createElement(Meeting, null), React.createElement(Partners, null), React.createElement(Testimony, null), React.createElement(JoinIn, null), React.createElement(Contact, null), React.createElement(Footer, null));
    },
    data: staticQueryData
  }));
}

export default Layout;