import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/353084560.json";
import React from "react";
import { StaticQuery } from "gatsby";

function Squad() {
  return React.createElement(StaticQuery, {
    query: "353084560",
    render: function render(data) {
      return React.createElement("section", {
        className: "bg-white h-auto pb-10px md:pb-20px xl:pb-0"
      }, React.createElement("div", {
        className: "container mx-auto h-full pt-30px lg:pt-60px lg:max-w-980 xl:max-w-1110"
      }, React.createElement("div", {
        className: "lg:float-left md:w-203px xl:w-290px mxa-ha text-center xl:text-left"
      }, React.createElement("h3", {
        className: "max-w-380 mx-auto text-28 md:text-34 lg:text-46 font-semibold"
      }, "Monte seu time conosco:"), React.createElement("img", {
        className: "w-auto mt-35px md:mt-21px mx-auto xl:ml-60px xl:mr-0 mb-35px xl:mb-82px",
        src: data.rosto.publicURL,
        alt: "rosto mulher"
      })), React.createElement("div", {
        className: "lg:float-right h-auto xl:w-734px xl:ml-86px lg:mb-60px text-14 text-black-3 "
      }, React.createElement("div", {
        className: "c-grid mx-20px lg:mx-0 md:float-left md:h-full lg:w-358px"
      }, data.squad1.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("div", {
          key: node.frontmatter.order,
          className: "mw424-mxa xl:mx-0 mb-28px md:mb-15px xl:mb-35 inline-flex"
        }, React.createElement("span", {
          className: "font-display pr-18px"
        }, React.createElement("span", {
          className: "text-cian-1 text-18 md:text-26"
        }, node.frontmatter.title), "\xA0", node.excerpt), data.icons.nodes.map(function (_ref2) {
          var name = _ref2.name,
              publicURL = _ref2.publicURL;
          return name === "icon_" + node.frontmatter.img && React.createElement("img", {
            key: name,
            src: publicURL,
            alt: name
          });
        }));
      })), React.createElement("div", {
        className: "c-grid mx-20px lg:mx-0 md:float-right md:h-full lg:w-358px"
      }, data.squad2.edges.map(function (_ref3) {
        var node = _ref3.node;
        return React.createElement("div", {
          key: node.frontmatter.order,
          className: "mw424-mxa xl:mx-0 mb-28px md:mb-15px xl:mb-35 inline-flex"
        }, React.createElement("span", {
          className: "font-display pr-18px"
        }, React.createElement("span", {
          className: "text-cian-1 text-18 md:text-26"
        }, node.frontmatter.title), "\xA0", node.excerpt), data.icons.nodes.map(function (_ref4) {
          var name = _ref4.name,
              publicURL = _ref4.publicURL;
          return name === "icon_" + node.frontmatter.img && React.createElement("img", {
            key: name,
            src: publicURL,
            alt: name
          });
        }));
      })))));
    },
    data: staticQueryData
  });
}

export default Squad;