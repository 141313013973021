import "core-js/modules/es6.regexp.constructor";
import staticQueryData from "../../../public/static/d/2681050328.json";
import React, { useState, useReducer } from 'react';
import { StaticQuery } from 'gatsby';
import Anchor from '../anchor';
import Alert from '../alert';

function Contact() {
  var _this = this;

  var _useState = useState(''),
      email = _useState[0],
      changeEmail = _useState[1];

  var _useState2 = useState(''),
      fullName = _useState2[0],
      changeFullName = _useState2[1];

  var _useState3 = useState(''),
      message = _useState3[0],
      changeMessage = _useState3[1];

  var _useState4 = useState(''),
      telefone = _useState4[0],
      changeTelefone = _useState4[1];

  var _useState5 = useState(false),
      showAlert = _useState5[0],
      changeShowAlert = _useState5[1];

  var initialState = {
    alert: '',
    title: '',
    content: ''
  };

  var _useReducer = useReducer(reducer, initialState),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  function handleCloseAlert() {
    changeShowAlert(false);
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'incomplete':
        changeShowAlert(true);
        return {
          alert: 'error',
          title: 'Campos vazios!',
          content: 'Por favor, preencha todos os campos.'
        };

      case 'error':
        changeShowAlert(true);
        return {
          alert: action.type,
          title: 'Falha ao enviar e-mail!',
          content: 'Por favor, tente novamente mais tarde.'
        };

      case 'invalid_email':
        changeShowAlert(true);
        return {
          alert: "error",
          title: "Email inválido!",
          content: "Por favor, insira um email válido."
        };

      case 'success':
        changeShowAlert(true);
        return {
          alert: action.type,
          title: 'Sucesso!',
          content: 'E-mail enviado corretamente, aguarde nosso retorno.'
        };

      default:
        return {
          initialState: initialState
        };
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    var testEmail = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);

    if (email && !testEmail.test(email)) {
      dispatch({
        type: 'invalid_email'
      });
    } else {
      if (email && fullName && message && telefone) {
        var data = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
          },
          mode: 'cors',
          body: JSON.stringify({
            email_address: email,
            status: 'subscribed',
            merge_fields: {
              FULLNAME: fullName,
              PHONE: telefone,
              MESSAGE: message
            },
            tag: 'Contact'
          })
        };
        fetch("" + process.env.GATSBY_API_CONTACT, data).then(function (res) {
          return res.json();
        }).then(function (res) {
          res.success ? dispatch({
            type: 'success'
          }) : dispatch({
            type: 'error'
          });
        }).catch(function (e) {
          dispatch({
            type: 'error'
          });
        });
      } else {
        dispatch({
          type: 'incomplete'
        });
      }
    }
  }

  return React.createElement(StaticQuery, {
    query: "2681050328",
    render: function render(data) {
      return React.createElement("section", {
        className: "h-auto bg-cover",
        style: {
          backgroundImage: "url(" + data.background_full.publicURL + ")"
        }
      }, React.createElement("div", {
        className: "bg-cian-5 h-auto pt-30px lg:pt-40px pb-30px lg:pb-60px"
      }, React.createElement(Anchor, {
        id: "contato"
      }), React.createElement("div", {
        className: "container mx-auto"
      }, React.createElement("div", {
        className: "sec-intro font-semibold"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-black-3"
      }, "Entre em contato "), React.createElement("span", {
        className: "text-white"
      }, "conosco."))), React.createElement("div", {
        className: "mw424-mxa md:max-w-677 lg:max-w-817 pt-15px"
      }, data.allMarkdownRemark.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "txtc-14-18 text-black-3 text-center mx-20px"
        }, node.excerpt);
      })), React.createElement("div", {
        className: "max-w-380 sm:max-w-424 md:max-w-677 lg:max-w-734 mxa-ha mt-25px md:mt-23px"
      }, React.createElement(Alert, {
        type: state.alert,
        title: state.title,
        content: state.content,
        show: showAlert,
        onClose: handleCloseAlert.bind(_this)
      }), React.createElement("form", {
        className: "mx-20px lg:mx-0"
      }, React.createElement("div", {
        className: "mb-20px"
      }, React.createElement("input", {
        "aria-label": "Fullname",
        className: "no-outline input-contact h-52px",
        type: "text",
        placeholder: "Nome Completo",
        required: true,
        value: fullName,
        onChange: function onChange(e) {
          return changeFullName(e.target.value);
        }
      })), React.createElement("div", {
        className: "mb-20px lg:h-52px"
      }, React.createElement("input", {
        "aria-label": "E-mail",
        className: "no-outline input-contact lg:float-left lg:w-357px mb-20px lg:mb-0 h-52px lg:mr-10px",
        type: "email",
        placeholder: "Email",
        required: true,
        value: email,
        onChange: function onChange(e) {
          return changeEmail(e.target.value);
        }
      }), React.createElement("input", {
        "aria-label": "Phone",
        className: "no-outline input-contact lg:float-right lg:w-357px h-52px lg:ml-10px",
        type: "text",
        placeholder: "Telefone",
        required: true,
        value: telefone,
        onChange: function onChange(e) {
          return changeTelefone(e.target.value);
        }
      })), React.createElement("div", {
        className: "w-full mb-15px"
      }, React.createElement("textarea", {
        "aria-label": "Message",
        className: "no-outline input-contact h-154px min-h-52 max-h-180 py-16px",
        type: "text",
        placeholder: "Mensagem",
        required: true,
        value: message,
        onChange: function onChange(e) {
          return changeMessage(e.target.value);
        }
      })), React.createElement("button", {
        className: "no-outline h-52px w-full md:w-245px bg-black-1 hover:bg-black-5 text-18 text-white font-semibold rounded",
        type: "submit",
        onClick: function onClick(e) {
          return handleSubmit(e);
        }
      }, "ENVIAR"))))));
    },
    data: staticQueryData
  });
}

export default Contact;