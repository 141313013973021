import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/57736867.json";
import { StaticQuery } from "gatsby";
import React from "react";
import Anchor from '../anchor';

function SecTestimony() {
  return React.createElement(StaticQuery, {
    query: "57736867",
    render: function render(data) {
      return React.createElement("section", {
        className: "h-full bg-white pt-30px lg:pt-40px pb-30px lg:pb-60px"
      }, React.createElement(Anchor, {
        id: "testemunho"
      }), React.createElement("div", {
        className: "container mx-auto lg:max-w-980 xl:max-w-1110"
      }, React.createElement("div", {
        className: "mx-20px font-semibold text-34 md:text-46 lg:text-58 text-center"
      }, React.createElement("h2", null, React.createElement("span", {
        className: "text-black-3"
      }, "O que nossos clientes "), React.createElement("span", {
        className: "text-cian-1"
      }, "pensam de n\xF3s?"))), React.createElement("div", {
        className: "xl:w-817px mx-auto text-center mt-30px lg:mt-25px lg:mb-53px"
      }, data.testimony.edges.map(function (_ref) {
        var node = _ref.node;
        return React.createElement("p", {
          key: node.frontmatter.title,
          className: "txtc-14-18 text-black-3 mx-20px"
        }, node.excerpt);
      })), React.createElement("div", {
        className: "max-w-380 sm:max-w-full lg:inline-flex lg:justify-between w-full mxa-ha"
      }, data.testimonys.edges.map(function (_ref2) {
        var node = _ref2.node;
        return React.createElement("div", {
          key: node.frontmatter.order,
          className: "flex-wrap sm:flex-no-wrap sm:inline-flex h-auto lg:w-307px mt-30px lg:mt-0 sm:content-between lg:content-start lg:flex-wrap mx-20px lg:mx-0"
        }, data.photos.nodes.map(function (_ref3) {
          var name = _ref3.name,
              publicURL = _ref3.publicURL;
          return name === node.frontmatter.img && React.createElement("img", {
            width: "50%",
            height: "50%",
            className: "sm:max-w-300 w-full mx-auto sm:float-left order-1",
            key: name,
            src: publicURL,
            alt: "foto " + node.frontmatter.title
          });
        }), React.createElement("div", {
          className: "px-5px sm:float-right md:pl-40px md:pr-0 lg:px-0 order-2"
        }, React.createElement("p", {
          className: "text-14 font-display text-center md:text-left mt-30px"
        }, node.excerpt), React.createElement("div", {
          className: "w-auto font-semibold text-center md:text-left mt-20px mb-20px lg:mb-0"
        }, React.createElement("p", {
          className: "text-16 text-cian-1"
        }, node.frontmatter.title), React.createElement("p", {
          className: "text-14 text-black-3"
        }, node.frontmatter.company))));
      }))));
    },
    data: staticQueryData
  });
}

export default SecTestimony;